<template>
  <div style="position: relative;">
    <ProgressSpinner v-if="isLoading" style="width:50px;height:50px;position: absolute; top: 50%;left: 50%;transform: translate(-50%, -50%);" strokeWidth="8" fill="transparent" animationDuration="1s" aria-label="Custom ProgressSpinner" />
    <table class="card dynamic-table" :style="isLoading === true ? 'background-color: lightgray;' : ''">
      <tr style="height:100px;">
        <td style="width:100%;">
          <table class="order_sub_table" style="table-layout:fixed;">
            <tbody>
              <tr style="height:30px;">
                <td class="container_sub_style" colspan="2" style="border:none;">
                  <div class="div_title2">
                    <div>
                      {{ account.unitCurrency }}-{{ account.currency }} 
                    </div>
                  </div>
                </td>
              </tr>
              <tr :style="isLoading === true ? 'height:30px;' : 'height:30px;background-color: aliceblue;'">
                <td colspan="2" style="text-align: center;font-weight:bold;">계좌</td>
              </tr>
              <tr >
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:60px;" v-tooltip="'보유'">보유<br>수량</div>
                    <div style="width:100%;" >
                      {{ (Number(account.balance) + Number(account.locked)).toLocaleString() }} {{ account.currency }}
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:60px;" v-tooltip="'매수평균가'">매수<br>평균가</div>
                    <div style="width:100%;">
                      {{ Math.round(Number(account.avgPrice)).toLocaleString() }} {{ account.unitCurrency }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr style="height:30px;">
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:60px;" v-tooltip="'매수금액'">매수<br>금액</div>
                    <div style="width:100%;">
                      {{ Math.round( ( Number(account.balance) + Number(account.locked) ) * Number(account.avgPrice) ).toLocaleString() }} {{ account.unitCurrency }}
                    </div>
                  </div>
                </td>
                <td class="container_sub_style"></td>
              </tr>
              <tr :style="isLoading === true ? 'height:30px;' : 'height:30px;background-color: aliceblue;'">
                <td colspan="2" style="text-align: center;font-weight:bold;">자동거래</td>
              </tr>
              <tr>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:60px;" v-tooltip="'총 자동거래 건 수'">거래<br>건수</div>
                    <div style="width:100%;"> 
                      <label style="color:red;">매수:{{ buyCount.toLocaleString() }}건</label><br>
                      <label style="color:blue;">매도:{{ sellCount.toLocaleString() }}건</label>
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:60px;" v-tooltip="'거래금액'">거래<br>금액</div>
                    <div style="width:100%;"> 
                      <label style="color:red;">매수:{{ Number(buyInvestments.toFixed(0)).toLocaleString() }}원</label><br>
                      <label style="color:blue;">매도:{{ Number(sellInvestments.toFixed(0)).toLocaleString() }}원</label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:60px;" v-tooltip="'투자수익률'">투자<br>수익률</div>
                    <div style="width:100%;"> 
                      {{ Number((profit + Math.round( ( Number(account.balance) + Number(account.locked) ) * Number(account.avgPrice))) / buyInvestments * 100 * (12/this.month)).toLocaleString()  }}%
                       <!-- {{Number((profit + Math.round( ( Number(account.balance) + Number(account.locked) ) * Number(account.avgPrice))) / (( Number(account.balance) + Number(account.locked) ) * Number(account.avgPrice)) * 100).toLocaleString()  }}% -->
                    </div>
                  </div>
                </td>
                <td class="container_sub_style">
                  <div class="div_center">
                    <div style="min-width:60px;" v-tooltip="'실현수익'">실현<br>수익</div>
                    <div style="width:100%;"> 
                      {{ Math.round(profit + Math.round( ( Number(account.balance) + Number(account.locked) ) * Number(account.avgPrice))).toLocaleString() }}원
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  mounted() {
    this.click({type:'GET_CLOSED_ORDERS'})
  },
  props: {
    account:Object,
    month:Number
  },
  data() { 
    return { 
      isLoading:false,
      profit:0.0,
      profitRate:0.0,
      buyInvestments:0.0,
      sellInvestments:0.0,
      buyCount:0,
      sellCount:0,
      doneOrders:[],
      limit:500,
    } 
  },
  methods: {
    // 데이터 초기화 메소드 추가
    resetData() {
      this.profit = 0.0
      this.profitRate = 0.0
      this.buyInvestments = 0.0
      this.sellInvestments = 0.0
      this.buyCount = 0
      this.sellCount = 0
      this.doneOrders = []
    },
    async click(param) {
      const type = param.type
      if(type === 'GET_CLOSED_ORDERS') {
        try {
          this.isLoading = true
          // 데이터 초기화
          this.resetData()
          
          const res = await this.api.axiosGql(type, {
            type: type, 
            startTime: this.api.getIsoDates(this.month), 
            endTime: this.api.getIsoDates(0), 
            state: 'done', 
            limit: this.limit, 
            id: this.api.getCookies('id'), 
            market: `${this.account.unitCurrency}-${this.account.currency}`
          })

          if(res.status === 200) {
            const orders = res.data
            orders.forEach(order => {
              const volume = Number(order.executed_volume)
              const price = Number(order.price)
              const fee = Number(order.paid_fee)
              
              if (order.side === 'bid') {
                this.buyCount++
                const buyInvestment = price * volume
                this.buyInvestments += Math.round(buyInvestment)
                this.profit -= (buyInvestment + fee)
              } else if (order.side === 'ask') {
                this.sellCount++
                const sellInvestment = price * volume
                this.sellInvestments += Math.round(sellInvestment)
                this.profit += (sellInvestment - fee)
              }
            })
          }
        } catch (error) {
          console.error('Error fetching closed orders:', error)
        } finally {
          this.isLoading = false
        }
      }
    }
  },
  // month prop이 변경될 때마다 데이터를 다시 조회하도록 watch 추가
  watch: {
    month: {
      handler(newMonth) {
        if (newMonth) {
          this.click({type:'GET_CLOSED_ORDERS'})
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
