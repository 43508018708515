<template>
  <div class="div_title">
    <span>
      {{ order.MARKET }}
    </span>
    <span>
      <Button type="button" label="삭제" @click="click({type:'DELETE_COIN_ORDER', idx:Number(order.IDX)})" severity="danger" size="small" />
    </span>
  </div>
  <table class="card dynamic-table" >
    <tr style="height:100px;">
      <td style="width:100%;">
        <table class="order_sub_table" style="table-layout:fixed;">
          <tbody>
            <tr style="height:30px;">
              <td class="container_sub_style">
                <div class="div_center">
                  <div style="min-width:60px;" >IDX</div>
                  <div style="width:100%;" >
                    {{ order.IDX }}
                  </div>
                </div>
              </td>
              <td class="container_sub_style">
                <div class="div_center">
                  <div style="min-width:60px;" >등록일</div>
                  <div style="width:100%;">
                    {{ this.api.getDateByFormat(Date(order.REG_DATE), 'YYYY.MM.DD HH:mm:ss' )}}
                  </div>
                </div>
              </td>
            </tr>
            <tr style="height:30px;">
              <td class="container_sub_style">
                <div class="div_center">
                  <div style="min-width:60px;" >Model</div>
                  <div style="width:100%;">
                    {{ order.MODEL }}
                  </div>
                </div>
              </td>
              <td class="container_sub_style">
                <div class="div_center">
                  <div style="min-width:60px;" >주문유형</div>
                  <div style="width:100%;">
                    {{ order.SIDE === 'ask' ? '매도' : '매수' }}
                  </div>
                </div>
              </td>
            </tr>
            <tr style="height:30px;">
              <td class="container_sub_style">
                <div class="div_center">
                  <div style="min-width:60px;" >주문<br>수량</div>
                  <div style="width:100%;">
                    {{ order.VOLUME }} {{ order.MARKET.split('-')[1] }} 
                  </div>
                </div>
              </td>
              <td class="container_sub_style">
                <div class="div_center">
                  <div style="min-width:60px;" >주문가격</div>
                  <div style="width:100%;">
                    {{ order.PRICE }}원
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  mounted() {
  },
  props: {
    order:Object,
    month:Number
  },
  data() { 
    return { 
    } 
  },
  methods: {
    click(param) {
      const type = param.type
      if(type === 'DELETE_COIN_ORDER') {
        console.log(type)
      }
    }
  }
}
</script>

<style scoped>
</style>
