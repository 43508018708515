<!-- QuartzGenerator.vue -->
<template>  
  <div :key="coinKey">
    <div class="div_title" id="coin-id">
      <context :context=cate />
    </div>
    <div v-if="this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true">
      <div class="card main" style="font-weight:normal">
      <p class="agree">Model01 : 주문 가격 변동(주기 : 15s)</p>
      <p class="agree">Model02 : 주문 가격 고정(주기 : 60s)</p>
      <p class="agree">Checker : 미체결된 주문이 모두 매수 && 매수 주문가보다 현재가가 10% 이상 높을 때 매수 주문 하나 취소</p>
      <p class="agree">SubJob  : 미체결된 주문이 모두 매도 && 매도 주문가보다 현재가가 10% 이상 낲을 때 새로운 별도의 Model02 실행</p>
      <p class="agree">Model01의 sellRate는 Model02d sellRate보다 작게 한다!</p>
    </div>
      <Tabs :value="mIdx">
        <TabList>
          <Tab value=0>자산</Tab>
          <Tab value=1>Jobs</Tab>
          <Tab value=2>주문내역</Tab>
        </TabList>
        <TabPanels>
          <TabPanel value=0>
            <div class="div_title2">
              <label>자산</label>
              <span>
                <Select v-model="range" :options="ranges" optionLabel="name" />&nbsp;
                <Button type="button" label="조회" @click="click({type:'GET_ACCOUNT'})" />
              </span>
            </div>
            <br>
            <div>
              <div v-if="coins.length === 1" class="no-result">조회해주세요.</div>
              <div v-else>
                <div v-for="(coin, index) in coins.slice(1)" :key="index">
                  <coinAccountC :account="coin" :month="range.month" />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value=1>
            <div class="div_title2">
              <span>
                Jobs
              </span>
              <span>
                <Button type="button" label="등록" @click="click({type:'INSERT_COIN_JOBS'})" />
              </span>
            </div>
            <br>
            <table class="card dynamic-table">
              <tr style="height:100px;">
                <td style="width:100%;">
                  <table class="order_sub_table" style="table-layout:fixed;">
                    <tbody>
                      <tr style="height:30px;">
                        <td class="container_sub_style">
                          <div class="div_center">
                            <div style="min-width:60px;" v-tooltip="'코인코드'">Market</div>
                            <div style="width:100%;">
                              <AutoComplete v-model="market" dropdown :suggestions="markets" @complete="marketSearch" style="width:100%;" />
                            </div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center">
                            <div style="min-width:60px;" v-tooltip="'매매 알고리즘'">Model</div>
                            <div style="width:100%;">
                              <AutoComplete v-model="model" dropdown :suggestions="models" @complete="modelSearch" style="width:100%;" />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr style="height:30px;">
                        <td class="container_sub_style">
                          <div class="div_center">
                            <div style="min-width:60px;" v-tooltip="'코인주문금액'">매매금액</div>
                            <div style="width:100%;" >
                              <InputNumber v-model="bidMoney" placeholder="코인주문11금액" size="small" :min="0" style="width:100%;"/>
                            </div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center">
                            <div style="min-width:60px;" v-tooltip="'주문프로세스실행주기(Period)'">주기</div>
                            <div style="width:100%;">
                              <InputText v-model="period" @click="this.visible = true" placeholder="실행주기 - 클릭" size="small" style="width:100%;"/>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr style="height:30px;">
                        <td class="container_sub_style">
                          <div class="div_center">
                            <div style="min-width:60px;" v-tooltip="'매수 주문시 현재가 대비 하락 비율'">매수가<br>비율</div>
                            <div style="width:100%;">
                              <InputNumber v-model="buyRate" inputId="horizontal-buttons" :min="0.1" buttonLayout="horizontal" :step="0.1" :minFractionDigits="1" :maxFractionDigits="1" mode="decimal" suffix="%" fluid size="small" style="width:100%;">
                              </InputNumber>
                            </div>
                          </div>
                        </td>
                        <td class="container_sub_style">
                          <div class="div_center">
                            <div style="min-width:60px;" v-tooltip="'매도 주문시 현재가 대비 상승 비율'">매도가<br>비율</div>
                            <div style="width:100%;">
                              <InputNumber v-model="sellRate" inputId="horizontal-buttons" :min="0.1" buttonLayout="horizontal" :step="0.1" :minFractionDigits="1" :maxFractionDigits="1" mode="decimal" suffix="%" fluid size="small" style="width:100%;">
                                <!-- <template #incrementbuttonicon>
                                  <span class="pi pi-plus" />
                                </template>
                                <template #decrementbuttonicon>
                                  <span class="pi pi-minus" />
                                </template> -->
                              </InputNumber>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </table>
            <div class="div_title2">
              <span>
                <Button type="button" label="실행" severity="secondary" @click="click({type:'restartCoin'})"></Button>&nbsp;
                <Button type="button" label="멈춤" severity="secondary" @click="click({type:'cancelCoin'})"></Button>
              </span>
              <span>
                <InputText style="width:100px;" v-model="keyword" v-on:keyup.enter="click({type:'SELECT_COIN_JOBS'})" placeholder="검색어" />&nbsp;
                <Button type="button" label="조회" @click="click({type:'SELECT_COIN_JOBS'})" />&nbsp;
              </span>
            </div>
            <br>
            <div class="card">
              <div v-if="jobs.length === 0" class="no-result">
                검색 결과가 없습니다
              </div>
              <div v-else>
                <Accordion value="0">
                  <AccordionPanel :value="index+1" v-for="(job, index) in jobs" :key="index" >
                    <AccordionHeader :class="index%2===1 ? 'datatable-background' : ''"> 
                      <div class="div_title2" style="width:100%;">
                        <span>{{ job.MARKET }}</span>
                        <span>{{ job.MODEL }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </div>  
                    </AccordionHeader>
                    <AccordionContent>
                      <div style="width:100%;" class="div_title">
                        <span>No. {{ job.IDX }}</span> 
                        <span>
                          <Button type="button" label="수정" @click="click({type:'UPDATE_COIN_JOBS', idx:job.IDX, market:job.MARKET, period:job.PERIOD, model:job.MODEL, bidMoney:job.BID_MONEY, sellRate:job.SELL_RATE, buyRate:job.BUY_RATE})" size="small" />&nbsp;
                          <Button type="button" label="삭제" @click="click({type:'DELETE_COIN_JOBS', idx:job.IDX})" size="small" severity="danger" />
                        </span>
                      </div>
                      <table class="card dynamic-table">
                        <tr style="height:100px;">
                          <td style="width:100%;" >
                            <table class="order_sub_table" style="table-layout:fixed;">
                              <tbody>
                                <tr style="height:30px;">
                                  <td class="container_sub_style">
                                    <div class="div_center">
                                      <div style="min-width:60px;" v-tooltip="'코인코드'">Market</div>
                                      <div style="width:100%;">
                                        <InputText :value="job.MARKET" size="small" style="width:100%;" disabled/>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="container_sub_style">
                                    <div class="div_center">
                                      <div style="min-width:60px;" v-tooltip="'매매 알고리즘'">Model</div>
                                      <div style="width:100%;">
                                        <AutoComplete v-model="job.MODEL" dropdown :suggestions="models" @complete="modelSearch" style="width:100%;" />
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr style="height:30px;">
                                  <td class="container_sub_style">
                                    <div class="div_center">
                                      <div style="min-width:60px;" v-tooltip="'코인주문금액'">매매금액</div>
                                      <div style="width:100%;" >
                                        <InputNumber v-model="job.BID_MONEY" placeholder="코인주문금액" size="small" :min="0" style="width:100%;"/>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="container_sub_style">
                                    <div class="div_center">
                                      <div style="min-width:60px;" v-tooltip="'주문프로세스실행주기(Period)'">주기</div>
                                      <div style="width:100%;">
                                        <InputText v-model="job.PERIOD" placeholder="실행주기 - 클릭" size="small" style="width:100%;"/>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr style="height:30px;">
                                  <td class="container_sub_style">
                                    <div class="div_center">
                                      <div style="min-width:60px;" v-tooltip="'매수 주문시 현재가 대비 하락 비율'">매수가<br>비율</div>
                                      <div style="width:100%;">
                                        <InputNumber v-model="job.BUY_RATE" inputId="horizontal-buttons" :min="0.1" buttonLayout="horizontal" :step="0.1" :minFractionDigits="1" :maxFractionDigits="1" mode="decimal" suffix="%" fluid size="small"  style="width:100%;">
                                        </InputNumber>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="container_sub_style">
                                    <div class="div_center">
                                      <div style="min-width:60px;" v-tooltip="'매도 주문시 현재가 대비 상승 비율'">매도가<br>비율</div>
                                      <div style="width:100%;">
                                        <InputNumber v-model="job.SELL_RATE" inputId="horizontal-buttons" :min="0.1" buttonLayout="horizontal" :step="0.1" :minFractionDigits="1" :maxFractionDigits="1" mode="decimal" suffix="%" size="small"  style="width:100%;">
                                          <!-- <template #incrementbuttonicon>
                                            <span class="pi pi-plus" />
                                          </template>
                                          <template #decrementbuttonicon>
                                            <span class="pi pi-minus" />
                                          </template> -->
                                        </InputNumber>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </AccordionContent>
                  </AccordionPanel>
                </Accordion>
              </div>
            </div>
            <Dialog v-model:visible="visible" header="반복" >
              <table class="dynamic-table" style="width:100%;">
                <table>
                  <tr>
                    <td><label for="startSecond" >Starting Second</label></td>
                    <td>
                      <select id="startSecond" v-model="schedule.startSecond" class="normalInput" >
                        <option v-for="sec in 60" :key="sec-1" :value="sec-1">
                          {{ String(sec-1).padStart(2, '0') }}
                        </option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <Divider/>
                    </td>
                  </tr>
                  <tr>
                    <td><label for="interval" class="text-sm font-medium">Repeat Every Second</label></td>
                    <td>
                      <input type="number" id="interval" v-model="schedule.interval" min="1" max="59" @input="handleIntervalChange" class="normalInput"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <Divider/>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <label class="text-sm font-medium">Additional Time Constraints</label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type="checkbox" id="specificMinute" v-model="schedule.useSpecificMinute" />
                      <label for="specificMinute">At specific minute:</label>
                    </td>
                    <td>
                      <select v-if="schedule.useSpecificMinute" v-model="schedule.minute" class="normalInput" :disabled="!schedule.useSpecificMinute" >
                        <option v-for="min in 60" :key="min-1" :value="min-1">
                          {{ String(min-1).padStart(2, '0') }}
                        </option>
                      </select>
                    </td>
                  </tr>
                    <tr>
                      <td>
                        <input type="checkbox" id="specificHour" v-model="schedule.useSpecificHour" />
                        <label for="specificHour">At specific hour:</label>
                      </td>
                      <td>
                        <select v-if="schedule.useSpecificHour" v-model="schedule.hour" class="normalInput" :disabled="!schedule.useSpecificHour" >
                          <option v-for="hr in 24" :key="hr-1" :value="hr-1" >
                            {{ String(hr-1).padStart(2, '0') }}
                          </option>
                        </select>
                      </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <Divider/>
                    </td>
                  </tr>
                </table>
              </table>
              <div class="no-result">
                <div class="text-sm mb-2">This will run:</div>
                <div class="text-sm text-gray-600" v-html="scheduleDescription" />
              </div>
              <Divider/>
              <div class="div_title2">
                <InputText :value="period" style='width:40%' disabled />
                <span>
                  <Button type="button" label="Cancel" severity="secondary" @click="visible = false"></Button>
                  <Button type="button" label="Ok" @click="visible = !visible"></Button>
                </span>
              </div>
            </Dialog>
          </TabPanel>
          <TabPanel value=2>
            <div class="div_title2">
              <label>주문내역</label>
              <span>
                <InputText v-model="ordersKeyword" placeholder="검색어" style="width:100px;"/>&nbsp;
                <Button type="button" label="조회" @click="click({type:'SELECT_COIN_ORDER'})" />
              </span>
            </div>
            <br>
            <div>
              <div v-if="orders.length === 0" class="no-result">조회해주세요.</div>
              <div v-else>
                <div v-for="(order, index) in orders" :key="index">
                  <coinOrderC :order="order" />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
    <div v-else>
      <login @login="login" pageKey="coin" /> 
    </div>
  </div>
</template>

<script>
import { ref, computed, inject } from 'vue'
import context from '@/components/common/Context.vue'
import ssr from '@/components/common/ssr'
import login from '@/components/common/Login.vue'
import router from '@/router'
import coinAccountC from '@/components/items/coinAccountItem.vue'
import coinOrderC from '@/components/items/coinOrderItem.vue'

export default {
  emits: ["setCookies", "setMenu", "login"],
  mounted() {
    // TODO
    if(this.api.getCookies('id') !== '1513898286') router.push({ name: 'home' })
    else if(this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true) {
      this.click({type:'GET_ALL_MARKETS'})
      this.click({type:'SELECT_ALL_MODELS'})
    }
    this.addJsonLd()
    this.api.setPage('coin')
    this.$emit("setMenu", {page:'coin'})
    console.log(this.api.getCookies('page'))    
    this.emitter.on('coinReload', this.reload)

  },
  unmounted() {
    this.emitter.off('coinReload', this.reload)
  },
  setup() {
    const ranges = ref([{name:'최근1개월', month:1}, {name:'최근3개월', month:3},{name:'최근6개월',month:6}])
    const range = ref({name:'최근1개월', month:1})
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 코인'
    const keywords = '코인,투자,coin,오토트레이드,자ㄴ동거래,비트코인,알트코인,재테크,가상화폐'
    const description = '가상화폐 자동 거래'
    const geek9Url = ssr.GEEK9_URL + 'coin'
    const visible = ref(false)
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const schedule = ref({
      startSecond: 0,
      interval: 1,
      useSpecificMinute: false,
      minute: 0,
      useSpecificHour: false,
      hour: 0
    })

    const handleIntervalChange = (event) => {
      const newValue = parseInt(event.target.value) || 1
      schedule.value.interval = Math.min(Math.max(newValue, 1), 59)
    }

    const period = computed(() => {
      const { startSecond, interval, useSpecificMinute, minute, useSpecificHour, hour } = schedule.value
      
      let seconds = interval === 1 ? startSecond.toString() : `${startSecond}/${interval}`
      let minutes = useSpecificMinute ? minute.toString() : '*'
      let hours = useSpecificHour ? hour.toString() : '*'
      
      return `${seconds} ${minutes} ${hours} * * ?`
    })

    const scheduleDescription = computed(() => {
      const { startSecond, interval, useSpecificMinute, minute, useSpecificHour, hour } = schedule.value
      
      let description = `Starting at ${startSecond} second`
      
      if (interval > 1) {
        description += `<br>repeating every ${interval} seconds`
      }
      
      if (useSpecificMinute) {
        description += ` at ${minute} minute <br>`
      }
      
      if (useSpecificHour) {
        description += ` at ${hour} hour <br>`
      }
      
      if (!useSpecificMinute && !useSpecificHour) {
        description += ' of every minute'
      }
      return description
    })
    const cate = ref([
      { label: '코인', route:'https://geek9.kr/coin' },
    ])
    const market = ref('')
    const markets = ref([])
    const allMarkets = ref([])
    const model = ref('')
    const models = ref([])
    const allModels = ref([])

    const marketSearch = (event) => {
      if (!allMarkets.value || !Array.isArray(allMarkets.value)) return

      const krwMarkets = allMarkets.value.filter(item => 
        item && item.market && item.market.startsWith('KRW-')
      )

      const formatMarket = (item) => 
        `${item.market.replace('KRW-', '')} (${item.korean_name}, ${item.english_name})`

      if (!event.query) {
        markets.value = krwMarkets.map(formatMarket)
        return
      }

      const searchTerm = event.query.toLowerCase()
      const filteredMarkets = krwMarkets.filter(item => 
        item.market.toLowerCase().includes(searchTerm) || 
        item.korean_name.toLowerCase().includes(searchTerm) || 
        item.english_name.toLowerCase().includes(searchTerm)
      )
      markets.value = filteredMarkets.map(formatMarket)
    }

    const modelSearch = (event) => {
      if (!allModels.value || !Array.isArray(allModels.value)) {
        return
      }

      const formatModel = (item) => item.MODEL

      if (!event.query) {
        // 검색어가 없을 때는 모든 모델을 보여줌
        models.value = allModels.value.map(formatModel)
        return
      }

      const searchTerm = event.query.toLowerCase()
      const filteredModels = allModels.value.filter(item => 
        item.MODEL.toLowerCase().includes(searchTerm)
      )
      models.value = filteredModels.map(formatModel)
    }

    const updateAllMarkets = (data) => {
      allMarkets.value = data
      marketSearch({ query: '' })
    }

    const updateAllModels = (data) => {
      allModels.value = data
      modelSearch({ query: '' })
    }
    
    const coins = ref([])
    coins.value.push({name:'전체', val:''})

    const updateAccount = (param) => {
      coins.value.push(param)  
    }

    const clearAccount = () => {
      coins.value = []
      coins.value.push({name:'전체', val:''})
    }

    return {
      ranges, range, coins, period, updateAccount, clearAccount, updateAllMarkets, marketSearch, market, markets, updateAllModels, modelSearch, model, models, handleIntervalChange, scheduleDescription, addJsonLd, visible, cate, schedule
    }
  },
  data(){
    return{
      coin:{name:'전체', val:''},
      mIdx : '0',
      coinKey:-1,
      bidMoney:0,
      buyRate:0.0,
      sellRate:0.0,
      startTime:'',
      endTime:'',
      keyword:undefined,
      ordersKeyword:undefined,
      jobs:[],
      accounts:[],
      orders:[]
    }
  },
  components: {
     context, login, coinAccountC, coinOrderC
  },
  methods: {
    reload(flag) {
      if(flag === 'dashboard') {
        this.coinKey++
        this.$emit("setMenu", {page:'coin'})
      }
    },
    login() {
      this.$emit('login')
    },
    async click(param) {
      const type = param.type
      if(type === 'GET_ALL_MARKETS') {
        try {
          const res = await this.api.axiosGql(type, {type:type})
          this.updateAllMarkets(res.data) 
        } catch (error) {
          console.error('Error fetching markets:', error)
        }
      } else if(type === 'SELECT_ALL_MODELS') {
        try {
          const res = await this.api.axiosGql(type, {type:type})
          this.updateAllModels(res.data.getAllModels) 
        } catch (error) {
          console.error('Error fetching markets:', error)
        }
      } else if(type === 'SELECT_COIN_JOBS') {
        await this.api.axiosGql(type, {type:type, id:this.api.getCookies('id'), keyword:this.keyword})
        .then( async (res) => { 
          if(res.status === 200) this.jobs = res.data
          else this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
        })
        .catch((err) => {
          console.log(err)
          this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
        })
      // } else if(type === 'GET_CLOSED_ORDERS') {
      //   await this.api.axiosGql(type, {type:type, state:'done', limit:this.limit, id:this.api.getCookies('id'), market:this.coin})
      //   .then( async (res) => { 
      //     if(res.status === 200) this.doneOrders = res.data
      //     else this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //     this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
      //   })
      } else if(type === 'INSERT_COIN_JOBS') {
        let msg = ''

        if(this.model === '') msg = '거래 모델을 선택해주세요.'
        else if(this.market === '') msg = '등록할 코인을 선택해주세요.'
        else if(this.model !== 'subJob') { 
          if(this.market === '') msg = '등록할 코인을 선택해주세요.'
          else if(this.bidMoney < 10000) msg = '매매 금액은 만 원 이상부터 가능합니다.'
          else if(this.sellRate === 0.0) msg = '목표 매도가 비율을 입력해주세요.'
          else if(this.buyRate === 0.0) msg = '목표 매수가 비율을 입력해주세요.'
        }
        
        if(msg === '') {
          await this.api.axiosGql(type, {type:type, id:this.api.getCookies('id'), market:(this.market).split(' ')[0], model:this.model, period:this.period, bidMoney:this.bidMoney, sellRate:this.sellRate, buyRate:this.buyRate})
          .then( async (res) => { 
            if(res.status === 200) {
              this.emitter.emit('confirm', {message: '등록되었습니다.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-info-circle'}) 
              this.click({type:'SELECT_COIN_JOBS'})
            } else this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
          })
          .catch((err) => {
            console.log(err)
            this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
          })
        } else this.emitter.emit('confirm', {message: msg, header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
      } else if(type === 'UPDATE_COIN_JOBS') {
        const hasMissing = Object.entries(param)
        .some(([key, value]) => {
          if (value === undefined || value === null || value === '') {
            this.emitter.emit('confirm', {message: `"${key}" 파라미터가 누락되었습니다.`, header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
            return true
          }
          return false
        })

        if (!hasMissing) {
          await this.api.axiosGql(type, param)
          .then( async (res) => { 
            if(res.status === 200) {
              this.emitter.emit('confirm', {message: '수정되었습니다.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-info-circle'}) 
            } else {
              this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
            }
          })
          .catch((err) => {
            console.log(err)
            this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
          })
        }
      } else if(type === 'DELETE_COIN_JOBS') {
        if(confirm("삭제할까요?")) {
          await this.api.axiosGql(type, param)
          .then( async (res) => { 
            if(res.status === 200) {
              this.emitter.emit('confirm', {message: '삭제되었습니다.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-info-circle'}) 
              this.jobs = []
              this.click({type:'SELECT_COIN_JOBS'})
            } else {
              this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
            }
          })
          .catch((err) => {
            console.log(err)
            this.emitter.emit('confirm', {message: '다시 시도해주세요.', header:String(this.api.getCookies('page')).toUpperCase(), icon:'pi pi-exclamation-triangle'})
          })
        }
      } else if(type === 'restartCoin') {
        await this.api.axiosGql(type, {type:type, id:this.api.getCookies('id')})
      } else if(type === 'cancelCoin') {
        await this.api.axiosGql(type, {type:type, id:this.api.getCookies('id')})
      } else if(type === 'GET_ACCOUNT') {
        await this.api.axiosGql(type, {type:type, containZero:0})
        .then( async (res) => {
          const json = res.data
          if(json && Array.isArray(json)) {
            // 기존 데이터 초기화
            this.clearAccount()
            
            // 새로운 데이터 추가
            json.forEach(item => {
              this.updateAccount(item)
            })
          }
        })
        // if(this.coins.length === 1) {
        //   await this.api.axiosGql(type, {type:type, containZero:0})
        //   .then( async (res) => {
        //     const json = res.data
        //     console.log(json)
        //     if(!(json === undefined || json.length === 0)) {
        //       for(let i=0; i<json.length; i++) {
        //         this.updateAccount(json[i])
        //       }
        //     }
        //   })
        // }
      } else if(type === 'SELECT_COIN_ORDER') {
        const type = param.type
        await this.api.axiosGql(type, {type:type, keyword:this.ordersKeyword})
        .then( async (res)=> {
          this.orders = res.data
        })
      }
    }
  }
}

</script>

<style scoped>
.datatable-background {
  background-color:#f9f9f9;
}
</style>